import React, { useEffect } from 'react';

const CalendlyWidget = () => {
  useEffect(() => {
    // Charger le script et la feuille de style de Calendly
    const script = document.createElement('script');
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    const link = document.createElement('link');
    link.href = "https://assets.calendly.com/assets/external/widget.css";
    link.rel = "stylesheet";
    document.head.appendChild(link);

    // Initialiser le widget une fois que le script est chargé
    script.onload = () => {
      // Vérifiez si Calendly est disponible dans window
      if (window.Calendly) {
        window.Calendly.initBadgeWidget({ 
          url: 'https://calendly.com/wecyde', 
          text: 'Planifier du temps avec nous', 
          color: '#0069ff', 
          textColor: '#ffffff', 
          branding: false 
        });
      }
    };

    // Nettoyage
    return () => {
      document.body.removeChild(script);
      document.head.removeChild(link);
    };
  }, []);

  return <div />;
};

export default CalendlyWidget;
