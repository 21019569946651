import React from 'react';
import ideation from '../../asset/svg.svg';
import creation from '../../asset/svg1.svg';
import test from '../../asset/svg2.svg';
import "./Etapes.css";


function Etapes() {
    return(
        <div className='ensemble'>
            <div className='ideation'>
                <img src={ideation} alt="logo ideation " />
                <p>Phase d'idéation</p>
            </div>
            <div className='creation'>
                <img src={creation} alt="logo creation" />
                <p>phase de création</p>
            </div>
            <div className='tests'>
                <img src={test} alt="logo test" />
                <p>Phase de tests</p>
            </div>
        </div>
    );
}

export default Etapes;