import React from "react";
import "./Button.css"; // Assurez-vous de créer ce fichier CSS

function Button({ label, onClick }) {
    return (
        <a href="https://calendly.com/wecyde/30min" target="_blank">
        <button className="custom-button" onClick={onClick}>
            {label}
        </button>
        </a>
    );
}

export default Button;
