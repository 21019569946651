import React, { useEffect, useRef, useState } from 'react';
import './tablette.css';
import tablette from "../../asset/Frame 4.svg";

function Tablette() {
    const [isVisible, setIsVisible] = useState(false);
    const tabletteRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setIsVisible(true);
            }
        }, { threshold: 0.3 });  // Déclencher quand 30% de l'élément est visible

        observer.observe(tabletteRef.current);

        return () => observer.disconnect();
    }, []);

    return (
        <div className={`Container-tablette ${isVisible ? 'slide-in-left' : ''}`} ref={tabletteRef}>
            <div className="gauche-tablette">
                <a href=""> <img src={tablette} alt="tablette" /></a>
            </div>
            <div className='droite-tablette'>
                <h3>Conception de site web</h3>
                <p>Notre équipe experte conçoit et lance des sites web uniques pour captiver votre audience et renforcer votre présence en ligne. Nous sommes dédiés à votre satisfaction, offrant un soutien continu technique et stratégique pour assurer votre succès à long terme.</p>

                <a href="https://calendly.com/wecyde/30min" target="_blank"><button>Prendre Rendez vous</button></a>
            </div>
        </div>
    );
}

export default Tablette;
