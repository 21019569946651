import React, { useState } from 'react';
import './Navbar.css';
import logo from '../../asset/logo.png';

const Navbar = () => {
  const [activeLink, setActiveLink] = useState('');

  const handleSetActiveLink = (link, event ) => {
    event.preventDefault(); // Empêche le comportement de lien par défaut
    setActiveLink(link);
    const section = document.querySelector(`#${link}`);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const links = [
    { name: 'Accueil', href: '/'},
    { name: 'Nos Services', href: '#services' }, // ID pour la section des services
    { name: 'Contact', href: '#contact' } // Suppose que vous avez un ID 'contact' dans votre page
  ];

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <a className="navbar-logo">
          <img src={logo} alt="Logo de la société weycde" />
        </a>
        <div className="navbar-links">
          {links.map((item, index) => (
            <a href={item.href}
               className={`navbar-item ${activeLink === item.name.toLowerCase() ? 'active' : ''}`}
               onClick={(e) => handleSetActiveLink(item.name.toLowerCase(),e)}
               key={index}>
              {item.name}
            </a>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
