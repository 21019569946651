import React, { useEffect, useRef, useState } from 'react';
import './CardGroup.css';
import phone from "../../asset/bigo.svg";

function CardGroup() {
    const [isVisible, setIsVisible] = useState(false);
    const containerRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setIsVisible(true);
            }
        }, { threshold: 0.3 });  // Déclencher quand 30% de l'élément est visible

        observer.observe(containerRef.current);

        return () => observer.disconnect();
    }, []);

    return (
        <div className={`Container ${isVisible ? 'slide-in-right' : ''}`} ref={containerRef}>
            <div className="gauche">
                <a href=""> <img src={phone} alt="phone" /></a>
            </div>
            <div className="droite">
                <h3>Application web et mobile</h3>
                <p>Transformez vos idées en applications mobiles iOS et Android attrayantes et fonctionnelles avec notre équipe de technologues passionnés. Pour un site web captivant ou une application convaincante, nous avons l'expertise nécessaire pour réaliser vos concepts numériques.</p>
                <a href="https://calendly.com/wecyde/30min" target="_blank"><button>Prendre rendez vous !</button></a>
                
            </div>
        </div>
    );
}

export default CardGroup;
